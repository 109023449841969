import '../styles/globals.css'
import '../styles/nprogress.css'
import 'antd/dist/antd.min.css'
import "tailwindcss/tailwind.css";
import Router from "next/router";
import NProgress from "nprogress";
// import 'wangeditor/src/assets/style/common.less'

Router.onRouteChangeStart = url => {
  NProgress.start();
};
Router.onRouteChangeComplete = () => NProgress.done();
Router.onRouteChangeError = () => NProgress.done();

function MyApp({ Component, pageProps }) {
  return <Component {...pageProps} />
}

export default MyApp
